<template>
  <div></div>
</template>
<script>
import { parseJwt } from '@/service/jwt'
export default {
  data() {
    return {}
  },
  async mounted() {
    const {
      to = '/',
      // 这个token是oa token
      token,
      loginid,
      ...rest
    } = this.$route.query
    const path = to
    const query = {
      ...rest,
    }
    try {
      const res = await this.$axios.post('/ums/weblogin/loginBySso', {
        token,
        loginname: loginid,
      })
      // 换取到当前系统的token
      const user = parseJwt(res.data.token)
      this.$store.commit('SET_TOKEN', { accessToken: res.data.token })
      this.$store.commit('SET_USER', user )
      setTimeout(() => {
        this.$router
          .replace({
            path,
            query,
          })
          .catch()
      })
    } catch (e) {
      this.$pageError(e)
    }
  },
}
</script>
